$body-width: 1920px; // 页面宽度
$navigation-height: 80px; // 导航栏高度
$navigation-active: #ffffff; // 导航栏激活颜色

.desktop-body {
  min-width: $body-width;
  background-color: #f6f7fe;
}

// 导航栏
.desktop-navigation {
  position: fixed;
  top: 0;
  left: 0;
  min-width: $body-width;
  width: 100%;
  height: $navigation-height;
  z-index: 100;
  overflow: hidden;

  &-bg {
    background: rgba(0, 0, 0, 0.4);
  }

  &-dark {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }

  &-light {
    background-color: #ffffff;
    // box-shadow: 0 2px 8px 0 #e6e7f2;
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.06);
  }

  &-wrapper {
    width: 1200px;
    height: $navigation-height;
    margin: 0 auto;
  }

  & .logo-top {
    float: left;
    width: 163px;
    height: $navigation-height;
    line-height: $navigation-height;
  }

  & .logo-top img {
    vertical-align: middle;
  }

  & .scrollactive-nav {
    margin-left: 413px;
    height: $navigation-height;
    line-height: $navigation-height;
    // float: left;
  }

  & .scrollactive-item {
    font-size: 16px;
    line-height: 24px;
    font-family: 'PingFangTC-Regular', 'Microsoft Yahei';
    margin: 0 6px;
    display: inline-block;
    padding: 4px 12px;
    box-sizing: border-box;

    &-light {
      color: #ffffff;
      $navigation-active: #ffffff;

      &.is-active {
        color: $navigation-active;
        // border-bottom: 2px solid $navigation-active;
      }

      &.is-active::after {
        background-color: $navigation-active;
      }
    }

    &-dark {
      color: #333333;
      $navigation-active: #333333;

      &.is-active {
        color: $navigation-active;
        // border-bottom: 2px solid $navigation-active;
      }

      &.is-active::after {
        background-color: $navigation-active;
      }
    }

    &.contact-item.is-active::after {
      display: none;
    }

    &.is-active::after {
      content: '';
      display: block;
      height: 2px;
      margin-top: 4px;
      border-radius: 1px;
    }
  }

  & .contact-item {
    color: #ffffff;
    background-color: #2785e2;
  }
}

//页面内容
// .desktop-main {
//   padding-top: $navigation-height;
// }

//头图
.desktop-banner {
  width: 100%;
  min-width: $body-width;
  background-color: #f6f7fe;
}

//智慧导游
.desktop-smartguide {
  width: 100%;
  min-width: $body-width;
  margin: 0 auto;
}

//大数据
.desktop-bigdata {
  width: 100%;
  min-width: $body-width;
  background-color: #142136;
  margin: 0 auto;
}

//精准营销
.desktop-marketing {
  width: 100%;
  min-width: $body-width;
  background-color: #f6f7fe;
  margin: 0 auto;
}

//管理工具
.desktop-management {
  width: 100%;
  min-width: $body-width;
  background-color: #ffffff;
  margin: 0 auto;
}

//社交联动
.desktop-sociality {
  width: 100%;
  min-width: $body-width;
  background-color: #f6f7fe;
  margin: 0 auto;
}

//联系我们
.desktop-contact {
  width: 100%;
  min-width: $body-width;
  background-color: #142136;
  margin: 0 auto;
}

//页脚
.desktop-footer {
  width: 100%;
  min-width: $body-width;
  background-color: #142136;
  margin: 0 auto;
}
